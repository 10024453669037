// Custom loading indicator with a message
Notiflix.Loading.pulse({
    backgroundColor: 'var(--color3-700)',
    cssAnimation: false,
    zindex: 99999,
});


  document.addEventListener("DOMContentLoaded", function() {
    const notiflix =  document.querySelector('.notiflix-loading');
    const masque =  document.querySelector('.loading__masque');
    notiflix.classList.add('hide-loading');
    // masque.classList.add('hide-loading');
});